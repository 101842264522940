export const CATEGORY = "CATEGORY";
export const PRODUCTS = "PRODUCT";
export const PRODUCT_DETAILS = "PRODUCT_DETAILS";
export const RESPONSE_MESSAGE = "RESPONSE_MESSAGE";
export const CURRENT_USER = "CURRENT_USER";
export const USER_ORDERS = "USER_ORDERS";
export const CART_STORAGE = "CART_STORAGE";
export const THEME_STORAGE = "THEME_STORAGE";
export const ORDER_STATUS = "ORDER_STATUS";
export const ORDERED_PRODUCTS = "ORDERED_PRODUCTS";
export const USERS = "USERS";
export const USERS_COUNT = "USERS_COUNT";
export const USER_ROLES = "USER_ROLES";
